import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// import headerStyles from "./header.css"

const AstNavbar = ({ siteTitle }) => (
  <nav class="navbar navbar-light bg-light fixed-top">
  <div class="container">
      <a class="navbar-brand" href="/"> <b>astoria.finance</b></a>
      <form class="d-flex">
        <a class="btn" href="/signup">Sign Up</a>
        <a class="btn btn-primary" href="/login">Log In</a>
      </form>
  </div>
  </nav>
)

AstNavbar.propTypes = {
  siteTitle: PropTypes.string,
}

AstNavbar.defaultProps = {
  siteTitle: ``,
}

export default AstNavbar
